import { graphql } from 'gatsby'
import React from 'react'
import get from 'lodash/get'
import Img from 'gatsby-image'
import { Link } from 'gatsby'

import SEO from '../components/seo'
import Layout from 'components/layout'

import '../scss/style.scss'

const HeaderLineIndex = () => {
  return (
    <div class="header bg-white">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <p class="display-4 pagetitle">Relation Art</p>
          </div>
        </div>
      </div>
    </div>
  )
}

const PictureLine = data => {
  return (
    <div class="header bg-white">
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-6">
            <p class="display-4 pagesubtitle">Life Coaching</p>
            <p class="lead my-5">
              A guided and inspired process for individuals, groups, couples,
              and families to achieve personal and professional goals. Your
              coach will guide you through life coaching techniques and
              practices, giving you a clearer understanding of your aspirations,
              goals and life purpose.
            </p>
          </div>
          <div class="col-12 col-md-6">
            <div class="devices pl-md-4 pt-5">
              <Img
                fluid={data.imageOne.childImageSharp.fluid}
                alt="Index page image"
                className="rounded shadow-lg"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const QuoteLine = () => {
  return (
    <div class="header bg-white pt-5">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <blockquote class="text-center">
              <p class="pagequote">
                You are the artist of your life. Don't give the paintbrush to
                anyone else.
              </p>
              <footer class="blockquote-footer">Anonymous</footer>
            </blockquote>
          </div>
        </div>
      </div>
    </div>
  )
}

const CardBlock = data => {
  return (
    <section class="container pt-5 pb-5">
      <div class="card-deck">
        <div class="card">
          <div class="card-header">
            <Img
              fluid={data.lifeCoaching.childImageSharp.fluid}
              alt="Life coaching"
              className="rounded shadow-lg"
            />
          </div>
          <div class="card-body">
            <h4 class="card-title mt-2">Coaching</h4>
            <p class="card-text">Life coaching individuals and teams</p>
            <ul class="list-unstyled">
              <li>
                <i class="fa fa-check text-primary mx-2"></i> One-on-one
                coaching
              </li>
              <li>
                <i class="fa fa-check text-primary mx-2"></i> Team coaching
              </li>
              <li>
                <i class="fa fa-check text-primary mx-2"></i> Personal
                development
              </li>
              <li>
                <i class="fa fa-check text-primary mx-2"></i> Goal setting
              </li>
            </ul>
          </div>
          <div class="card-footer">
            <Link to="/coaching/" className="btn btn-primary stretched-link">
              Read More
            </Link>
          </div>
        </div>
        <div class="card">
          <div class="card-header">
            <Img
              fluid={data.relationshipCoaching.childImageSharp.fluid}
              alt="Relationship coaching"
              className="rounded shadow-lg"
            />
          </div>
          <div class="card-body">
            <h4 class="card-title mt-2">Relationship</h4>
            <p class="card-text">
              Relationship coaching of couples and individuals
            </p>
            <ul class="list-unstyled">
              <li>
                <i class="fa fa-check text-primary mx-2"></i> Marriage coaching
              </li>
              <li>
                <i class="fa fa-check text-primary mx-2"></i> Pre-marital
                coaching
              </li>
              <li>
                <i class="fa fa-check text-primary mx-2"></i> Family coaching
              </li>
            </ul>
          </div>
          <div class="card-footer">
            <Link
              to="/relationship/"
              className="btn btn-primary stretched-link"
            >
              Read More
            </Link>
          </div>
        </div>

        <div class="card">
          <div class="card-header">
            <Img
              fluid={data.mentalHealth.childImageSharp.fluid}
              alt="Mental health coaching"
              className="rounded shadow-lg"
            />
          </div>
          <div class="card-body">
            <h4 class="card-title mt-2">Mental Health</h4>
            <p class="card-text">Life coaching and personal development</p>
            <ul class="list-unstyled">
              <li>
                <i class="fa fa-check text-primary mx-2"></i> Anxiety
              </li>
              <li>
                <i class="fa fa-check text-primary mx-2"></i> Depression
              </li>
              <li>
                <i class="fa fa-check text-primary mx-2"></i> Stress
              </li>
              <li>
                <i class="fa fa-check text-primary mx-2"></i> Fear
              </li>
            </ul>
          </div>
          <div class="card-footer">
            <Link
              to="/mentalhealth/"
              className="btn btn-primary stretched-link"
            >
              Read More
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ({ data, location }) => (
  <Layout location={location} crumbLabel="Home">
    <SEO site={get(data, 'site.meta')} title="Relation Art" />
    {HeaderLineIndex()}
    {PictureLine(data)}
    {QuoteLine()}
    {CardBlock(data)}
  </Layout>
)

export const query = graphql`
  query {
    imageOne: file(relativePath: { eq: "dandelion-445228_1920.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    lifeCoaching: file(relativePath: { eq: "purposes-1963850_1920.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    relationshipCoaching: file(
      relativePath: { eq: "balloons-892806_1920.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mentalHealth: file(relativePath: { eq: "rock-4234793_1920.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
